// extracted by mini-css-extract-plugin
export var additionalInfoContainer = "style-module--additional-info-container--PXsav";
export var additionalInfoIcon = "style-module--additional-info-icon--lBIIx";
export var additionalInfoIconAndTitle = "style-module--additional-info-icon-and-title--iVlpH";
export var additionalInfoTitle = "style-module--additional-info-title--85svg";
export var addressCityInput = "style-module--address-city-input--af33R";
export var addressInputs = "style-module--address-inputs--RX2wv";
export var addressNumberInput = "style-module--address-number-input--yNvv7";
export var addressStreetNameInput = "style-module--address-street-name-input--+lb6E";
export var agreeButton = "style-module--agree-button--Yayzm";
export var applicantContainer = "style-module--applicant-container--HWH3N";
export var applicantIcon = "style-module--applicant-icon--XXEB6";
export var applicantTitle = "style-module--applicant-title--QxtFC";
export var birthdateContainer = "style-module--birthdate-container--6rbX4";
export var buttonsContainer = "style-module--buttons-container--OBXzU";
export var coverageContainer = "style-module--coverage-container--ZFWRk";
export var coverageDateInput = "style-module--coverage-date-input--fqLK9";
export var coverageDates = "style-module--coverage-dates--8gdTv";
export var coverageDraftDay = "style-module--coverage-draft-day--lJ6+6";
export var datePicker = "style-module--date-picker--W5TUo";
export var draftDayText = "style-module--draft-day-text--TTFWT";
export var firstNameInputContainer = "style-module--first-name-input-container--Mxlup";
export var householdContainer = "style-module--household-container--lVpA3";
export var householdIcon = "style-module--household-icon--k8k7X";
export var householdIconAndTitle = "style-module--household-icon-and-title--s4FFo";
export var householdTitle = "style-module--household-title--xSz9o";
export var inputs = "style-module--inputs--+yby3";
export var lastInputs = "style-module--last-inputs--u-3ZQ";
export var lastNameInputContainer = "style-module--last-name-input-container--Fy97E";
export var nextButton = "style-module--next-button--nfdCz";
export var previousButton = "style-module--previous-button--L7nVO";
export var priceDisclaimer = "style-module--price-disclaimer--PrW+W";
export var streetAndNumberContainer = "style-module--street-and-number-container--xHuwA";
export var weightNotAllowed = "style-module--weight-not-allowed--pWEWq";
export var weightNotAllowedContainer = "style-module--weight-not-allowed-container--v5hYq";
export var weightNotAllowedIcon = "style-module--weight-not-allowed-icon--IyqS8";
export var weightNotAllowedIconAndText = "style-module--weight-not-allowed-icon-and-text--Vgio6";
export var weightNotAllowedText = "style-module--weight-not-allowed-text--NKdBh";