// extracted by mini-css-extract-plugin
export var allowed = "style-module--allowed--D8jtB";
export var blocked = "style-module--blocked--Je4Ge";
export var btnNoToAll = "style-module--btn-no-to-all--f1SFL";
export var categoryContainer = "style-module--category-container--v4oQI";
export var eligibilityQuestion = "style-module--eligibility-question--+CV7M";
export var eligibilityQuestionError = "style-module--eligibility-question-error--vHqvm";
export var eligibilityQuestionIndex = "style-module--eligibility-question-index--e8cuX";
export var eligibilityQuestionOk = "style-module--eligibility-question-ok--kfUXQ";
export var eligibilityQuestionRadio = "style-module--eligibility-question-radio--bmQNP";
export var eligibilityQuestionText = "style-module--eligibility-question-text--i4pat";
export var eligibilityQuestionsContainer = "style-module--eligibility-questions-container--HBwD6";
export var eligibilityQuestionsInnerContainer = "style-module--eligibility-questions-inner-container--Rcfc8";
export var eligibilityQuestionsTitle = "style-module--eligibility-questions-title--92BWk";
export var nextButton = "style-module--next-button--hYqbt";
export var noButton = "style-module--no-button--kybvY";