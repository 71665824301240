import React, { useEffect } from "react";
import { Container } from "../../../";
import { v4 as uuidv4 } from "uuid";
import * as css from "../eligibility-questions/style.module.scss";
import { Button, Radio, Input, Space } from "antd";
import { connect } from "react-redux";
import Toastify from "toastify-js";

const EligibiltyQuestionsRaw = (props) => {
  const [isEligibilityToastOpen, setIsEligibilityToastOpen] =
    React.useState(false);

  const answerQuesion = (v, q, index) => {
    props.answerEligibilibityQuestion({
      ...q,
      index,
      answer: q.possibleAnswers[Number(!v.target.value)],
    });

    if (
      v.target.checked &&
      q.possibleAnswers[Number(!v.target.value)] !== q.expected_answer
    ) {
      if (isEligibilityToastOpen) return;
      Toastify({
        text: "Based on your answer we are unable to issue coverage.",
        duration: 2000,
        newWindow: true,
        close: true,
        gravity: "bottom",
        position: "center",
        backgroundColor: "#D33F49",
        stopOnFocus: true,
        className: "notification",
        callback: () => setIsEligibilityToastOpen(false),
      }).showToast();
      setIsEligibilityToastOpen(true);
    }
  };

  return (
    <Container>
      {props.applications[0].questions.map((question, i) => {
        if (question.questions.length == 0) return null;

        const questions = question.questions.map((question) => {
          question.answers.forEach((q) => {
            if (!["yes", "no"].includes(q)) {
              throw new Error("UNEXPECTED QUESTION FORMAT");
            }
          });

          return {
            id: uuidv4(),
            application_index: i,
            question: question.question,
            possibleAnswers: question.answers,
            answer: question.answer,
            expected_answer: question.expected_answer,
          };
        });

        return (
          <Container>
            <Container className={css.eligibilityQuestionsInnerContainer}>
              <Container className={css.categoryContainer}>
                <Container
                  className={`${css.eligibilityQuestionsTitle} ${
                    questions.some(
                      (q) => q.answer && q.answer !== q.expected_answer
                    )
                      ? css.blocked
                      : questions.every((q) => q.answer)
                      ? css.allowed
                      : ""
                  }`}
                >
                  {question.category}
                </Container>
                <Container className={css.eligibilityQuestions}>
                  {questions.map((q, index) => {
                    return (
                      <Container className={css.eligibilityQuestion} key={q.id}>
                        <Container style={{ display: "flex" }}>
                          <Container
                            className={css.eligibilityQuestionIndex}
                          ></Container>{" "}
                          <Container
                            className={`${css.eligibilityQuestionText} ${
                              q.answer
                                ? q.answer !== q.expected_answer
                                  ? css.eligibilityQuestionError
                                  : css.eligibilityQuestionOk
                                : ""
                            } `}
                          >
                            <div
                              dangerouslySetInnerHTML={{ __html: q.question }}
                            />{" "}
                          </Container>
                        </Container>
                        <Container
                          className={`${css.eligibilityQuestionRadio} hidden-on-mobile`}
                        >
                          <Radio.Group
                            value={
                              q.answer ? (q.answer == "yes" ? 1 : 0) : undefined
                            }
                            onChange={(v) => {
                              answerQuesion(v, q, index);
                            }}
                            buttonStyle="solid"
                            size="large"
                          >
                            <Space direction="horizontal">
                              <Radio value={1}>Yes</Radio>
                              <Radio value={0}>No</Radio>
                            </Space>
                          </Radio.Group>
                        </Container>
                        <Container
                          className={`${css.eligibilityQuestionRadio} hidden-on-web`}
                        >
                          <Radio.Group
                            value={
                              q.answer ? (q.answer == "yes" ? 1 : 0) : undefined
                            }
                            onChange={(v) => {
                              answerQuesion(v, q, index);
                            }}
                            buttonStyle="solid"
                            size="large"
                          >
                            <Radio value={1}>Yes</Radio>
                            <Radio value={0}>No</Radio>
                          </Radio.Group>
                        </Container>
                      </Container>
                    );
                  })}
                </Container>
                <Container className={css.btnNoToAll}>
                  <Button
                    onClick={() =>
                      questions.forEach((q, index) => {
                        props.answerEligibilibityQuestion({
                          ...q,
                          index,
                          answer: "no",
                        });
                      })
                    }
                  >
                    Answer <span className={css.noButton}>No</span> to all
                  </Button>
                </Container>
              </Container>
            </Container>
          </Container>
        );
      })}

      <Container className={css.nextButton}>
        <Button
          disabled={
            !props.applications[0].questions.every((q) =>
              q.questions.every((q2) => q2.answer == "no")
            )
          }
          type="primary"
          onClick={() => {
            props.next && props.next();
          }}
        >
          Next
        </Button>
      </Container>
    </Container>
  );
};

const mapStateToProps = ({ enrollment }) => {
  return {
    applications: [...enrollment.universal.applications],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    answerEligibilibityQuestion: (payload) =>
      dispatch({ type: `ANSWER_ELIGIBILITY_QUESTION_UNIVERSAL`, payload }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EligibiltyQuestionsRaw);
