import React, { useState, useEffect } from "react";

import { Container, DatePicker, Icon, TextInput, Select } from "../../..";

import * as css from "./style.module.scss";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/engine.json";

import { Button } from "antd";
import { connect } from "react-redux";

import moment from "moment";
import { handleInputChange } from "../../../../utils";

import _ from "lodash";

const AdditionalInfoRaw = (props) => {
  const [loading, setLoading] = useState(true);

  const effectiveDate = props.effectiveDate;
  const draftDay = props.draftDay;
  const availableDays = [
    {
      name: "5 days",
      id: 5,
    },
    {
      name: "7 days",
      id: 7,
    },
    {
      name: "10 days",
      id: 10,
    },
    {
      name: "15 days",
      id: 15,
    },
  ];

  useEffect(() => {
    new Promise((resolve) => setTimeout(resolve, 1200)).then(() => {
      setLoading(false);
    });
  }, []);
  const params = JSON.parse(localStorage.getItem("universal_params"));

  params.dependents = params.dependents ? JSON.parse(params.dependents) : [];
  const spouse = props.applicants.spouse;
  const dependents = props.applicants.dependents || [];
  const isValid = () => {
    let spouseValid = true;
    if (
      spouse &&
      (!props.spouse.first_name ||
        !props.spouse.last_name ||
        !props.spouse.date_of_birth)
    ) {
      spouseValid = false;
    }

    let dependentsValid = true;

    dependents.forEach((d) => {
      if (!d.first_name || !d.last_name || !d.date_of_birth) {
        dependentsValid = false;
      }
    });

    return (
      !spouseValid ||
      !dependentsValid ||
      !props.city ||
      !props.date_of_birth ||
      !props.street_address ||
      !draftDay
    );
  };

  return loading ? (
    <Container
      style={{
        display: "flex",
        width: "100vw",
        marginTop: "60px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: 300, height: 300 }}
      />
    </Container>
  ) : (
    <Container className={css.additionalInfoContainer}>
      <Container className={css.additionalInfoIconAndTitle}>
        <Container className={css.additionalInfoIcon}>
          <Icon fill="#004C64" icon="additional-info" />
        </Container>
        <Container className={css.additionalInfoTitle}>
          Enter your personal info:
        </Container>
      </Container>
      <Container className={css.inputs}>
        <Container className={css.applicantContainer}>
          <Container className={css.applicantTitle}>
            <Icon
              fill="#89D8F0"
              className={css.applicantIcon}
              icon="user-circle"
            />{" "}
            Main Applicant:
          </Container>
          <Container>
            <Container>
              <Container className={css.birthdateContainer}>
                <DatePicker
                  disabled
                  name="test-enrollment-enrollment-tools-additionalinfo-input-datebirth"
                  value={
                    typeof props.date_of_birth === "object"
                      ? props.date_of_birth
                      : typeof props.date_of_birth === "string"
                      ? moment(props.date_of_birth)
                      : null
                  }
                  onChange={(e) => {
                    props.setAdditionalInfo({ key: "date_of_birth", value: e });
                  }}
                  before={"Date Of Birth"}
                  focusedlabel="Date of Birth"
                />
              </Container>
            </Container>
            <Container></Container>
          </Container>
        </Container>

        {spouse && (
          <Container className={css.applicantContainer}>
            <Container className={css.applicantTitle}>
              <Icon
                fill="#89D8F0"
                className={css.applicantIcon}
                icon="spouse-circle"
              />{" "}
              Spouse:
            </Container>
            <Container>
              <Container>
                <Container className={css.firstNameInputContainer}>
                  <TextInput
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: {
                          ...props.spouse,
                          first_name: handleInputChange(e),
                        },
                      });
                    }}
                    value={props.spouse.first_name}
                    focusedlabel="Fist Name"
                    placeholder="First Name"
                  />
                </Container>
                <Container className={css.lastNameInputContainer}>
                  <TextInput
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: {
                          ...props.spouse,
                          last_name: handleInputChange(e),
                        },
                      });
                    }}
                    value={props.spouse.last_name}
                    placeholder="Last Name"
                    focusedlabel="Last Name"
                  />
                </Container>
                <Container className={css.birthdateContainer}>
                  <DatePicker
                    disabled
                    value={
                      typeof props.spouse.date_of_birth === "object"
                        ? props.spouse.date_of_birth
                        : typeof props.spouse.date_of_birth === "string"
                        ? moment(props.spouse.date_of_birth)
                        : null
                    }
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: { ...props.spouse, date_of_birth: e },
                      });
                    }}
                    before={"Date Of Birth"}
                    focusedlabel="Date of Birth"
                  />
                </Container>
              </Container>
              <Container></Container>
            </Container>
          </Container>
        )}
        {dependents.map((dep, ind) => {
          return (
            <Container className={css.applicantContainer}>
              <Container className={css.applicantTitle}>
                <Icon
                  fill="#89D8F0"
                  className={css.applicantIcon}
                  icon="dependent-circle"
                />{" "}
                Dependent {ind + 1}:
              </Container>
              <Container>
                <Container>
                  <Container className={css.firstNameInputContainer}>
                    <TextInput
                      value={dependents[ind].first_name}
                      placeholder="First Name"
                      focusedlabel="First Name"
                      onChange={(e) => {
                        dependents[ind].first_name = handleInputChange(e);
                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                    />
                  </Container>
                  <Container className={css.lastNameInputContainer}>
                    <TextInput
                      value={dependents[ind].last_name}
                      placeholder="Last Name"
                      focusedlabel="Last Name"
                      onChange={(e) => {
                        dependents[ind].last_name = handleInputChange(e);
                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                    />
                  </Container>
                  <Container className={css.birthdateContainer}>
                    <DatePicker
                      disabled
                      value={
                        typeof dependents[ind].date_of_birth === "object"
                          ? dependents[ind].date_of_birth
                          : typeof dependents[ind].date_of_birth === "string"
                          ? moment(dependents[ind].date_of_birth)
                          : null
                      }
                      before={"Date Of Birth"}
                      onChange={(e) => {
                        dependents[ind].date_of_birth = e;

                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                      focusedlabel="Date of Birth"
                    />
                  </Container>
                </Container>
              </Container>
            </Container>
          );
        })}
      </Container>
      <Container className={css.lastInputs}>
        <Container className={css.householdContainer}>
          <Container className={`${css.householdIconAndTitle}`}>
            <Container className={`${css.householdIcon} `}>
              <Icon fill="#004C64" icon="building" />
            </Container>
            <Container>
              <Container
                style={{ marginLeft: "2px" }}
                className={css.householdTitle}
              >
                Complete Household Address:
              </Container>
            </Container>
          </Container>
          <Container className={css.addressInputs}>
            <Container className={css.addressCityInput}>
              <TextInput
                name="test-enrollment-enrollment-additionalinfo-input-city"
                value={props.city}
                onChange={(e) => {
                  props.setAdditionalInfo({
                    key: "city",
                    value: handleInputChange(e),
                  });
                }}
                placeholder="City"
                focusedlabel="City"
              />
            </Container>
            <Container className={css.addressCityInput}>
              <TextInput
                name="test-enrollment-enrollment-additionalinfo-input-city"
                value={props.street_address}
                onChange={(e) => {
                  props.setAdditionalInfo({
                    key: "street_address",
                    value: handleInputChange(e),
                  });
                }}
                placeholder="Street address"
                focusedlabel="Street address"
              />
            </Container>
          </Container>
        </Container>
        <Container className={css.coverageContainer}>
          <Container className={`${css.householdIconAndTitle}`}>
            <Container className={`${css.householdIcon} `}>
              <Icon fill="#004C64" icon="heart-schedule" />
            </Container>
            <Container
              style={{ marginLeft: "2px" }}
              className={css.householdTitle}
            >
              Select the Coverage Start Date:
            </Container>
          </Container>

          <Container className={css.coverageDates}>
            <Container className={css.coverageDateInput}>
              <DatePicker
                disabledDate={(m) => {
                  const now = moment(new Date());
                  if (now >= m || now.add(90, "day") <= m) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                before="Coverage Start Date"
                className={css.datePicker}
                onChange={(v) => {
                  props.setAdditionalInfo({
                    key: "effectiveDate",
                    value: v,
                  });
                }}
                value={effectiveDate}
              />
            </Container>

            <Container
              className={`${css.coverageDateInput} ${css.coverageDraftDay}`}
            >
              <Container className={`${css.draftDayText}`}>
                How many days before the start date would you like to be charged
                ?
              </Container>
              <Select
                name="test-enrollment-enrollment-additionalinfo-select-police-length"
                value={draftDay}
                size="large"
                before={
                  <Container style={{ paddingRight: "66px", fontWeight: 500 }}>
                    Draft Day
                  </Container>
                }
                onChange={(e) => {
                  props.setAdditionalInfo({
                    key: "draftDay",
                    value: e,
                  });
                }}
                options={availableDays.map((m) => {
                  return {
                    label: m.name,
                    value: m.id,
                  };
                })}
              />
            </Container>
          </Container>
        </Container>
      </Container>

      <Container className={css.buttonsContainer}>
        <Container className={css.previousButton}>
          <Button
            onClick={() => {
              props.previous && props.previous();
            }}
            type="primary"
            shape="round"
          >
            Previous
          </Button>
        </Container>
        <Container className={css.nextButton}>
          <Button
            disabled={isValid()}
            onClick={() => {
              props.next && props.next();
            }}
            type="primary"
            shape="round"
          >
            Next
          </Button>
        </Container>
      </Container>
    </Container>
  );
};

const mapStateToProps = ({
  agentMetadata,
  baseUrl,
  enrollment,
  applicants,
}) => {
  return {
    applicants,
    agentMetadata,
    baseUrl,
    enrollment,
    ...enrollment.universal.additionalInfo,
    date_of_birth: enrollment.universal.additionalInfo.date_of_birth
      ? enrollment.universal.additionalInfo.date_of_birth
      : applicants.date_of_birth,
    spouse:
      Object.keys(enrollment.universal.additionalInfo.spouse).length > 0
        ? enrollment.universal.additionalInfo.spouse
        : applicants.spouse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAdditionalInfo: (payload) =>
      dispatch({ type: `SET_ADDITIONAL_INFO_UNIVERSAL`, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfoRaw);
